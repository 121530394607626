import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * ClickFunnels connection api
 */
export const clickFunnelsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['ClickFunnels', 'Integrations'],
  endpoints: (build) => ({
    connectClickFunnels: build.mutation({
      query: (data) => ({
        url: 'apps/clickfunnels/auth/connect',
        method: 'GET',
        data,
      }),
      invalidatesTags: ['ClickFunnels', 'Integrations'],
    }),
  }),
});

export const { useConnectClickFunnelsMutation } = clickFunnelsApi;
