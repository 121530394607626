import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { JustValueFormatter } from 'components/tables/NinjaTable/formatters';
import {
  RoiFormatter,
  RoasFormatter,
  SpentFormatter,
} from 'components/tables/NinjaTable/formatters';
import { AdFormatter } from 'components/tables/NinjaTable/formatters/sourceFormatters';

const list = [
  {
    name: 'Name',
    key: 'ad',
    resizable: true,
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    renderCell: ({ row }) => (
      <AdFormatter row={row} editStatus={true} enableReportsFilter={false} />
    ),
    renderSummaryCell: () => 'Totals',
  },
  {
    name: 'Ad Spent',
    key: 'ad_spent',
    sortable: true,
    renderCell: JustValueFormatter,
    renderSummaryCell: (data) => {
      return <SpentFormatter row={data.row} />;
    },
  },
  {
    name: 'Impressions',
    key: 'impressions',
    sortable: true,
    renderCell: ValueFormatter,
    renderSummaryCell: ValueFormatter,
  },
  {
    name: 'Clicks',
    key: 'clicks',
    sortable: true,
    renderCell: ValueFormatter,
    renderSummaryCell: ValueFormatter,
  },
  {
    key: 'roi',
    name: 'ROI',
    sortable: true,
    renderCell: RoiFormatter,
    renderSummaryCell: RoiFormatter,
  },
  {
    key: 'roas',
    name: 'ROAS',
    sortable: true,
    renderCell: RoasFormatter,
    renderSummaryCell: RoasFormatter,
  },
  {
    name: 'CPA',
    key: 'cpa',
    sortable: true,
    renderCell: JustValueFormatter,
    renderSummaryCell: JustValueFormatter,
  },
  {
    name: 'CPC',
    key: 'cpc',
    sortable: true,
    renderSummaryCell: JustValueFormatter,
  },
];

const AdsTable = (props) => {
  return (
    <NinjaTable
      cardProps={{ disable: true }}
      title='Ads'
      dataProvider='adsList'
      columns={list}
      sortableColumns
      {...props}
    />
  );
};

export default AdsTable;
