import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Widget from 'components/Widget/Widget';
import { JustValueFormatter } from 'components/tables/NinjaTable/formatters';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { OwerflowNameFormatter } from 'components/tables/NinjaTable/formatters';
import { NameDefinition } from 'components/NameDefinition';

const ProductReturns = ({ height, index, movingButton, data, configuration }) => {
  const { dates } = useWidgetDate('product_returns' + index);

  const columns = [
    {
      key: 'product_title',
      name: 'Product Title',
      resizable: true,
      width: 250,
      sortable: true,
      renderCell: ({ row }) => {
        return (
          <OwerflowNameFormatter
            title={row.product_title}
            className='!bg-background-light rounded-xl text-xs !font-semibold p-1.5 py-2.5'
          />
        );
      },
      renderSummaryCell: () => 'Totals',
    },
    {
      key: 'orders_returned',
      name: (
        <NameDefinition
          name='Orders Returned'
          definiton='Total number of products that have been returned.'
        />
      ),
      // sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
    {
      key: 'quantity_returned',
      name: (
        <NameDefinition
          name='Quantity Returned'
          definiton='Total number of products that were returned.'
        />
      ),
      // sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
    {
      key: 'returns_value',
      name: (
        <NameDefinition name='Returns Value' definiton='Total value of the products returned' />
      ),
      // sortable: true,
      renderCell: JustValueFormatter,
      renderSummaryCell: JustValueFormatter,
    },
  ];

  return (
    <Widget
      icon={
        data?.defaultFilter?.integration_type ? (
          <img
            src={`/icons/integrations/${data?.defaultFilter?.integration_type}.svg`}
            className='w-5 h-5 '
          />
        ) : null
      }
      tableWidget
      integrationType={data?.defaultFilter?.integration_type}
      widget_key={'product_returns' + index}
      title={data.title}
      index={index}
      movingButton={movingButton}
      deleteWidget={true}
      data={data}
      default_filter={data.defaultFilter}
      configuration={configuration}
      disableDateFilter
      disableStatusFilter
      disableAccountsFilter
      integrationsSelect
      DisableFilterButton={false}
      design={false}
    >
      <NinjaTable
        columns={columns}
        dataProvider='integrationProductReturns'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default ProductReturns;
