import { Dropdown, Tooltip, Button } from 'antd';
import React from 'react';
import useQueryParams from 'hooks/useQueryParams';
import ninjaApi from 'store/redux/apis';
import Icon from '@mdi/react';
import { mdiLink } from '@mdi/js';
import Ninja from '@ninja';

const AdPreviewFormatter = ({ row, level }) => {
  const { searchParams } = useQueryParams();
  const { group_by = 'adset' } = searchParams;

  const [getOuterReportingLinks, { data, isLoading }] = ninjaApi.useLazyOuterReportingLinksQuery();

  const showLinks = () => {
    const params =
      group_by === 'organic'
        ? { level: level, id: row.url.id }
        : { level: level, id: row[level].id };
    getOuterReportingLinks(params).unwrap();
  };

  const items = React.useMemo(() => {
    if (!data || !data.data) return [];

    return data.data.map((item, index) => {
      const { tracking_url, short_url } = item;

      return {
        label: (
          <div className='flex flex-center justify-between gap-4 text-xs'>
            {item.name || 'No Name'}
            <Tooltip title={tracking_url || short_url}>
              <a href={tracking_url || short_url} target='_blank' rel='noopener noreferrer'>
                Open
              </a>
            </Tooltip>
          </div>
        ),
        key: index,
      };
    });
  }, [data]);

  return (
    <Dropdown
      style={{ background: 'red' }}
      className='adPreview'
      menu={{
        items,
      }}
      trigger={['click']}
      onVisibleChange={(visible) => {
        if (visible) {
          showLinks();
        }
      }}
    >
      <Button
        type='text'
        size='small'
        shape='circle'
        className='w-fit'
        icon={<Icon path={mdiLink} color={Ninja.colors.light} size={0.8} />}
        loading={isLoading}
      />
    </Dropdown>
  );
};

export default AdPreviewFormatter;
