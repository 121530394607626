import React from 'react';
import PropTypes from 'prop-types';
import AdsTable from 'modules/ads/tables/AdsTable';

const AccountInfoAdsTab = (props) => {
  const { dates, accountId, ...rest } = props;

  return (
    <div>
      <AdsTable
        {...rest}
        cardProps={{ disable: true }}
        defaultFilters={{
          network_account_id: accountId,
          ...dates,
        }}
      />
    </div>
  );
};

AccountInfoAdsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoAdsTab;
