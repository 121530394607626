import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Button } from 'antd';
import { useConnectClickFunnelsMutation } from 'modules/apps/apps/ClickFunnelsApp/api/clickfunnels.api';

const ClickFunnelsDrawer = (props) => {
  const [useConnectClickFunnelsRequest, { isLoading }] = useConnectClickFunnelsMutation();

  const startConnectingClickFunnels = async () => {
    return await useConnectClickFunnelsRequest()
      .unwrap()
      .then((data) => {
        const w = window.open(
          data.redirect_url,
          'ClickFunnels Login MyDataNinja',
          'width=600,height=600'
        );

        return w;
      });
  };

  return (
    <AutomaticDrawer size='large' title='ClickFunnels' {...props}>
      <Button type='primary' onClick={startConnectingClickFunnels} isLoading={isLoading}>
        Connect New Store
      </Button>
    </AutomaticDrawer>
  );
};

ClickFunnelsDrawer.requiredParams = {
  app: (app) => 'clickfunnels' === app,
};

export default ClickFunnelsDrawer;
