import React, { useEffect, useMemo, useState } from 'react';
import {
  mdiHandshake,
  mdiFormatListBulleted,
  mdiAccountGroup,
  mdiAccountSearch,
  mdiPlus,
  mdiFormSelect,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import P from 'router/paths';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import useCrmForms from 'modules/CRMModule/hooks/useCrmForms';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import DateRangePicker from 'components/DateRangePicker';

const CrmMainMenu = ({ dateRangeComponent }) => {
  const { workspace } = useWorkspace();
  const { forms } = useCrmForms();
  const { queryParams } = useWidgetDate('global');

  const queryString = useMemo(() => queryParams, [queryParams]);

  const appendQuery = (path) => (queryString ? `${path}${queryString}` : path);

  const items = useMemo(() => {
    return [
      {
        label: (
          <Link style={{ color: 'unset' }} to={appendQuery(P.CRM.CONTACTS)}>
            Contacts
          </Link>
        ),
        key: 'contacts',
        icon: <Icon path={mdiAccountGroup} />,
      },
      {
        label: (
          <Link style={{ color: 'unset' }} to={appendQuery(P.CRM.DEALS)}>
            Deals
          </Link>
        ),
        key: 'deals',
        icon: <Icon path={mdiHandshake} />,
      },
      {
        label: 'Leads',
        key: 'leads',
        icon: <Icon path={mdiFormatListBulleted} />,
        children: [
          {
            label: 'Forms',
            icon: <Icon path={mdiFormSelect} />,
            key: 'forms',
            children: forms?.length
              ? forms.map((form) => ({
                  label: (
                    <Link
                      style={{ color: 'unset' }}
                      to={appendQuery(P.CRM.DATA_LIST.replace(':id', form.id))}
                    >
                      {form.name} - {form.leads_count} Leads
                    </Link>
                  ),
                  icon: <Icon path={mdiAccountSearch} />,
                  key: `${form.id}:list`,
                }))
              : [
                  {
                    label: 'No Forms',
                    key: 'no_forms',
                    disabled: true,
                  },
                ],
          },
          {
            label: (
              <Link style={{ color: 'unset' }} to={appendQuery(P.CRM.INDEX)}>
                Create New Form
              </Link>
            ),
            key: 'create_form',
            icon: <Icon path={mdiPlus} />,
          },
        ],
      },
    ];
  }, [workspace.crm_form_id, forms, queryString]);

  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();

  const findItemByPath = (items, path) => {
    for (const item of items) {
      if (item.children) {
        const found = findItemByPath(item.children, path);
        if (found) {
          return found;
        }
      }
      if (item.label?.props?.to?.startsWith(path)) {
        return item;
      }
    }
    return null;
  };

  useEffect(() => {
    const selectedItem = findItemByPath(items, location.pathname);
    setSelected(selectedItem ? selectedItem.key : 'leads');
  }, [location.pathname, items]);

  const onSelect = ({ key }) => {
    const findItemByKey = (items, key) => {
      for (const item of items) {
        if (item.key === key) {
          return item;
        }
        if (item.children) {
          const found = findItemByKey(item.children, key);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const selectedItem = findItemByKey(items, key);
    if (selectedItem) {
      setSelected(key);
      const url = selectedItem.url;
      if (url) {
        history.push(appendQuery(url));
      }
    }
  };

  return (
    <div className='flex px-4 pr-8 items-center justify-between flex-col sm:flex-row gap-2 pt-1'>
      <Menu
        className='w-full order-1 sm:order-0 !border-none'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
      <div className='sm:max-w-44 order-0 sm:order-1 w-full'>
        <DateRangePicker
          placement='bottomRight'
          size='middle'
          formatter='yyyy-MM-dd'
          changeGlobal={true}
          placeholder={['Date from', 'Date to']}
          date_key={selected}
        />
      </div>
    </div>
  );
};

export default CrmMainMenu;
