import { currency_options } from '@ninja/consts';

export default function getFormattedValue(value, currency = null) {
  let currency_config = currency ? currency_options[currency] : window.currency_config;

  if (!currency_config) return value;

  const numericValue = Number(value);
  if (isNaN(numericValue)) return `${currency_config.symbol}0`;

  // check if the number has a decimal part
  const hasDecimal = numericValue % 1 !== 0;
  const decimalPlaces = hasDecimal ? 2 : 0;

  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(numericValue);

  if (currency_config.sign_location === 'pre') {
    return numericValue < 0
      ? `${currency_config.symbol} ${formattedValue}`
      : `${currency_config.symbol} ${formattedValue}`;
  } else {
    return numericValue < 0
      ? `${currency_config.symbol} ${formattedValue}`
      : `${currency_config.symbol} ${formattedValue}`;
  }
}
