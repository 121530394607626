import ninjaApi from 'store/redux/apis/ninja.api';

export const adsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Ads'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get All Ads
     */
    adsList: builder.query({
      query: (body) => ({
        url: '/network/ads',
        method: 'POST',
        body,
      }),
      providesTags: ['Ads'],
    }),
  }),
});

export const { useAdsListQuery } = adsApi;
