import React, { useEffect, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import ninjaApi from 'store/redux/apis';
import { Descriptions, Spin, Button } from 'antd';
import { CountryFormatter } from 'modules/CRMModule/formatters';
import { DateFormatter, IntegrationFormatter } from 'components/tables/NinjaTable/formatters';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import Ninja from '@ninja';
import P from 'router/paths';
import { useHistory } from 'react-router-dom';

const ContactInfoDrawer = (props) => {
  const { searchParams, removeParams, setUrlParams } = useQueryParams();
  const { contact_id } = searchParams;
  const history = useHistory();

  const [fetchContact, { isLoading }] = ninjaApi.useLazyGetSingleContactQuery();
  const [contactData, setContactData] = useState(null);

  useEffect(() => {
    if (contact_id) {
      fetchContact({ id: contact_id })
        .unwrap()
        .then(setContactData)
        .catch(() => setContactData(null));
    }
  }, [contact_id]);

  const filterContact = (filter, params) => {
    history.push(P.CRM.CONTACTS);
    removeParams(['contact_id', 'date_from', 'date_to']);
    setUrlParams({ [filter]: params });
  };

  const items = contactData
    ? [
        {
          label: 'ID',
          children: (
            <Button
              type='text'
              onClick={() => filterContact('id', contactData.id)}
              icon={<Icon path={mdiFilter} color={Ninja.colors.light} size={0.8} />}
            >
              {contactData.id || '-'}
            </Button>
          ),
        },
        { label: 'Source', children: <IntegrationFormatter row={contactData} /> || '-' },
        { label: 'First Name', children: contactData.first_name || '-' },
        { label: 'Last Name', children: contactData.last_name || '-' },
        { label: 'Middle Name', children: contactData.middle_name || '-' },
        {
          label: 'Email',
          children: (
            <Button
              type='text'
              onClick={() => filterContact('email', contactData.email)}
              icon={
                contactData.email && <Icon path={mdiFilter} color={Ninja.colors.light} size={0.8} />
              }
            >
              {contactData.email || '-'}
            </Button>
          ),
        },
        { label: 'Gender', children: contactData.gender || '-' },
        { label: 'Date of Birth', children: contactData.date_of_birth || '-' },
        { label: 'Job Title', children: contactData.job_title || '-' },
        {
          label: 'Phone',
          children: (
            <Button
              type='text'
              onClick={() => filterContact('phone', contactData.phone)}
              icon={
                contactData.phone && <Icon path={mdiFilter} color={Ninja.colors.light} size={0.8} />
              }
            >
              {contactData.phone || '-'}
            </Button>
          ),
        },
        {
          label: 'Country',
          children: <CountryFormatter value={contactData.country} editable={false} />,
        },
        { label: 'City', children: contactData.city || '-' },
        { label: 'Address 1', children: contactData.address_1 || '-' },
        { label: 'Address 2', children: contactData.address_2 || '-' },
        { label: 'Emergency Phone', children: contactData.emergency_phone || '-' },
        { label: 'Personal Number', children: contactData.personal_number || '-' },
        { label: 'State', children: contactData.state || '-' },
        { label: 'Company Name', children: contactData.company_name || '-' },
        { label: 'Company Email', children: contactData.company_email || '-' },
        { label: 'Postal Code', children: contactData.postal_code || '-' },
        { label: 'Marital Status', children: contactData.marital_status || '-' },
        { label: 'Deals Count', children: contactData.deals_count || 0 },
        {
          label: 'Synced',
          children: contactData.created_at ? (
            <DateFormatter defaultValue={contactData.created_at} />
          ) : (
            '-'
          ),
        },
        {
          label: 'Registered',
          children: contactData.registered_at ? (
            <DateFormatter defaultValue={contactData.registered_at} />
          ) : (
            '-'
          ),
        },
      ]
    : [];

  return (
    <AutomaticDrawer size='large' title={`Contact ID ${contact_id}`} {...props}>
      {isLoading ? (
        <Spin size='large' className='m-auto w-full' />
      ) : (
        <Descriptions
          labelStyle={{
            width: '130px',
            height: 50,
            padding: 5,
            fontSize: '14px',
            fontWeight: 500,
            color: 'black',
          }}
          bordered
          size='small'
          column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
          items={items}
        />
      )}
    </AutomaticDrawer>
  );
};

ContactInfoDrawer.requiredParams = {
  contact_id: (p) => parseInt(p) > 0,
};

export default ContactInfoDrawer;
