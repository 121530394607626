import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Skeleton, Tabs } from 'antd';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import useQueryParams from 'hooks/useQueryParams';
import BadgedTabHeader from 'components/Tab/BadgetTabHeader';
import AccountInfoConversionsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoConversionsTab';
import AccountInfoInfoTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoInfoTab';
import AccountInfoCampaignsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoCampaignsTab';
import AccountInfoAdsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoAdsTab';
import AccountInfoAdsetsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoAdsetsTab';
import DateRangePicker from 'components/DateRangePicker';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const defaultTab = 'info';

const NetworkAccountInfoDrawer = (props) => {
  const { dates, setDate } = useWidgetDate('global');

  const { searchParams, ...rest } = props;
  const { account_info_id, tab = defaultTab } = searchParams;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({
    id: parseInt(account_info_id),
    filters: { ...dates },
  });
  const { setUrlParams, removeParams } = useQueryParams();

  const tabs = useMemo(
    () => [
      {
        label: 'Account Info',
        key: 'info',
        children: <AccountInfoInfoTab accountId={account?.id} filters={dates} />,
      },
      {
        label: <BadgedTabHeader label='Campaigns' />,
        key: 'campaigns',
        children: <AccountInfoCampaignsTab accountId={account?.id} dates={dates} />,
      },
      {
        label: <BadgedTabHeader label='Adsets' />,
        key: 'adsets',
        children: <AccountInfoAdsetsTab accountId={account?.id} dates={dates} />,
      },
      {
        label: <BadgedTabHeader label='Ads' />,
        key: 'ads',
        children: <AccountInfoAdsTab accountId={account?.id} dates={dates} />,
      },
    ],
    [account]
  );

  return (
    <AutomaticDrawer
      onClose={() => removeParams(['tab'])}
      size='large'
      bodyStyle={{ padding: 0 }}
      title={account?.name ? `Account: ${account?.name} Details` : `Account ID ${account_info_id}`}
      {...rest}
    >
      <div className='w-fit ml-auto m-4 w-fit'>
        <DateRangePicker
          showExpandDays
          format='YYYY-MM-DD'
          placement='bottomRight'
          size='middle'
          value={[dates.date_from, dates.date_to]}
          placeholder={['Date from', 'Date to']}
          onChange={({ date_from, date_to }) => {
            setDate({
              date_from: date_from,
              date_to: date_to,
            });
          }}
        />
      </div>
      <Skeleton loading={isFetching} active>
        <div className='px-4'>
          <Tabs
            defaultActiveKey={tab}
            activeKey={tab}
            onChange={(tab) => {
              setUrlParams({ tab: tab });
            }}
            items={tabs}
          />
        </div>
      </Skeleton>
    </AutomaticDrawer>
  );
};

NetworkAccountInfoDrawer.requiredParams = {
  account_info_id: (p) => parseInt(p) > 0,
};

export default NetworkAccountInfoDrawer;
