import woocommerceDrawers from 'modules/apps/apps/WoocommerceApp/drawers';
import shopifyDrawers from 'modules/apps/apps/ShopifyApp/drawers';
import mailerliteDrawers from 'modules/apps/apps/MailerLiteApp/drawers';
import cscartDrawers from 'modules/apps/apps/CscartApp/drawers';
import metaAppDrawers from 'modules/apps/apps/MetaApp/drawers';
import googleAdsAppDrawers from 'modules/apps/apps/GoogleAdsApp/drawers';
import bingAdsAppDrawers from 'modules/apps/apps/BingAdsApp/drawers';
import tiktokAppDrawers from 'modules/apps/apps/TiktokApp/drawers';
import HubSpotAppDrawer from 'modules/apps/apps/HubSpotApp/drawers';
import ClickFunnelsDrawer from 'modules/apps/apps/ClickFunnelsApp/drawers';

const AppsDrawer = [
  ...cscartDrawers,
  ...mailerliteDrawers,
  ...shopifyDrawers,
  ...woocommerceDrawers,
  ...metaAppDrawers,
  ...googleAdsAppDrawers,
  ...bingAdsAppDrawers,
  ...tiktokAppDrawers,
  ...HubSpotAppDrawer,
  ...ClickFunnelsDrawer,
];

export default AppsDrawer;
